
import React from 'react'
import {Grid,Typography, Box, Container} from '@cbmisorg/client-app'
import { appStyle } from '../../../../../../appHelper/appStyle'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary'
import { App_Primary_Color } from '../../../../../../appHelper/appColor'

const classes={
    avatarIcon:{
        width:"80px !important",
        height:"80px !important",
        border:`2px solid ${App_Primary_Color} !important`
    },
    container:{
        position:"relative",
        margin:"125px auto 50px",
        sm:{
            position:"relative",
            margin:"50px auto 50px",
        }
    },
    card:{
        minHeight:"315px",
        border:`1px solid ${App_Primary_Color} !important`
    },
    card0:{
        background:`url(${require("../../../../../../assets/images/cuberBg.jpg")}) !important`,
    },
    card1:{
        background:`url(${require("../../../../../../assets/images/cuberBg.jpg")}) !important`,
    }
  }
function ConsultationServices({lang}) {
 const labels = dictionary?.components?.public?.landingPage?.home?.consultationServices
 

  return (
    <React.Fragment>
        <Container pt-10 mt-10  id="consultationServices">
            <Grid container sx={appStyle?.container} justify="center" pb-5>
                <Grid item xs="12">
                    <Typography as="h4">{labels?.title?.[lang]}</Typography>
                </Grid>
            </Grid>
        </Container>
        <Container pb-y mb-10>
            <Grid container sx={appStyle?.container} spacing={2}>
                {
                    labels?.lstServices?.map((training,index)=>{
                        return(
                            <Grid item xs="12" lg="6">
                                <Box sx={{...classes?.card,...classes?.[`card${index}`]}} className='boxConsultationServices'>
                                    <Grid container>
                                        <Grid item xs="12">
                                            <Typography as="h6" color={'primary'} sx={appStyle?.title1}>{training?.name?.[lang]}</Typography>
                                        </Grid>
                                        <Grid item xs="12" >
                                            <Typography as="body2" color={'secondary'}>{training?.describe?.[lang]}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Container>
    </React.Fragment>
  )
}

export default ConsultationServices
