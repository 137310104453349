export const App_Primary_Color = "#B08730" 
export const App_Second_Color = "#EAE3DF"
export const App_Beige_Color1 = "#D9B5A1" 
export const App_Beige_Color2 ="#F2EEEB"
export const App_Light_Color = "#F6F4F2"


export const App_Dark_Color = "#201e1d"


export const App_White = "#FFFFFF"
export const App_Text_Color = "#6B6C70"
export const App_Black = "#000000"
