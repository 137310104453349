import React from "react";
import { ParallaxScrolling } from "@cbmisorg/client-app";
 
const classes = {
  parallaxScrolling: {
    minHeight: "45px !important",
    maxHeight: "45px !important",
    position: "relative",
  },
};

function ParallaxScrollingApp() {
  return (
    <React.Fragment>
      <ParallaxScrolling src={require("../../assets/images/ParallaxScrolling.jpg")} sx={classes?.parallaxScrolling} loading="lazy"  /> 
    </React.Fragment>
  );
}

export default ParallaxScrollingApp;
