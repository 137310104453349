import "./style/var.css";
import "./style/main.css";

class AppStyleConfig {
  constructor(intStyleSheetSxIndex, objCSSRuleSxIndex) {
    this.intStyleSheetSxIndex = intStyleSheetSxIndex;
    this.objCSSRuleSxIndex = objCSSRuleSxIndex;
  }
}
export var appStyleConfig = new AppStyleConfig(-1, {});
export function updateAppConfig({ intStyleSheetSxIndex = undefined, objCSSRuleSxIndex = undefined }) {
  if (intStyleSheetSxIndex !== null && intStyleSheetSxIndex !== undefined) {
    appStyleConfig.intStyleSheetSxIndex = intStyleSheetSxIndex;
  }
  if (objCSSRuleSxIndex !== null && objCSSRuleSxIndex !== undefined) {
    appStyleConfig.objCSSRuleSxIndex = objCSSRuleSxIndex;
  }
}

//CbmisRouter
export {
  BrowserRouter,
  createBrowserRouter,
  HashRouter,
  Link,
  NavLink,
  createHashRouter,
  AbortedDeferredError,
  Await,
  MemoryRouter,
  Navigate,
  NavigationType,
  Outlet,
  Route,
  Router,
  RouterProvider,
  Routes,
  createMemoryRouter,
  createPath,
  createRoutesFromChildren,
  createRoutesFromElements,
  defer,
  isRouteErrorResponse,
  generatePath,
  json,
  matchPath,
  matchRoutes,
  parsePath,
  redirect,
  renderMatches,
  resolvePath,
  unstable_useBlocker,
  useActionData,
  useAsyncError,
  useAsyncValue,
  useHref,
  useInRouterContext,
  useLoaderData,
  useLocation,
  useMatch,
  useMatches,
  useNavigate,
  useNavigation,
  useNavigationType,
  useOutlet,
  useOutletContext,
  useParams,
  useResolvedPath,
  useRevalidator,
  useRouteError,
  useRouteLoaderData,
  useRoutes,
  useLinkClickHandler,
  useSearchParams,
} from "react-router-dom";

/*** system*start ***/

//SecureRoute
export * as SecureRoute from "./system/cbmisRouter/SecureRoute/SecureRoute";

//cbmisFunction
export * as ClientFunction from "./system/cbmisFunction";

//CbmisTest
export * as ClientTest from "./system/cbmisTest/CbmisTest";

//CbmisData
export * as ClientData from "./system/cbmisData/CbmisData";

//CbmisDirection
export * as ClientDirection from "./system/cbmisDirection/CbmisDirection";
export * as   setDirectionByLanguage from "./system/cbmisDirection/CbmisDirection";

//ClientNotification
export * as ClientNotification from "./system/cbmisNotification/cbmisNotification";

//cbmis CbmisInfiniteScroll
export { default as InfiniteScroll } from "./system/CbmisInfiniteScroll/CbmisInfiniteScroll";

/*** system*end ***/

/*** hook*start ***/

export { default as ThemeProvider } from "./api/ThemeProvider/ThemeProvider";

//CbmisContextApi
export { default as AppProvider } from "./api/cbmisContextApi/AppProvider";

//useCheckUserLoginKey
export { default as useCheckUserLoginKey } from "./hook/useCheckUserLoginKey";

//cbmis formapi
export { default as FormControl } from "./api/cbmisFormapi/FormControl";
export { default as useForm } from "./api/cbmisFormapi/useForm";
export { default as FormField } from "./api/cbmisFormapi/FormField";

/*** hook*end ***/

/*** sybSystem*start ***/
//PermissionControl
export { default as PermissionControl } from "./api/CbmisPermissions/PermissionControl";

//ManageCategories
export { default as ManageCategories } from "./api/ManageCategories/ManageCategories";
export { default as SelectCategory } from "./api/ManageCategories/SelectCategory";

//cbmis camera
export { default as Camera } from "./api/cbmisCamera/camera/Camera";
export { default as useCamera } from "./api/cbmisCamera/useCamera.js";

export { default as FileUploader } from "./api/cbmisUploader/uploadImage/ImageUploader";

export { default as ChatBot } from "./api/cbmisChat/ChatBot";

export { default as VoiceRecorder } from "./api/cbmisVoiceRecorder/CbmisVoiceRecorder";
export { default as useRecorder } from "./api/cbmisVoiceRecorder/useRecorder";

// export { default as AppStorage } from "./api/appStorage/AppStorage";

/*** subSystem*end ***/

//#region cbmis dataDisplay
export { default as Avatar } from "./material/dataDisplay/Avatar/Avatar";
export { default as Badge } from "./material/dataDisplay/Badge/Badge";
export { default as Chip } from "./material/dataDisplay/Chip/Chip";
export { default as Counter } from "./material/dataDisplay/Counter/Counter";
export { default as Modal } from "./material/dataDisplay/Modal/Modal";
export { default as ModalHeader } from "./material/dataDisplay/Modal/ModalHeader";
export { default as ModalBody } from "./material/dataDisplay/Modal/ModalBody";
export { default as ModalFooter } from "./material/dataDisplay/Modal/ModalFooter";
export { default as Tooltip } from "./material/dataDisplay/Tooltip/Tooltip";
export { default as Typography } from "./material/dataDisplay/Typography/Typography";
export { default as Table } from "./material/dataDisplay/Table/Table";
export { default as TableHead } from "./material/dataDisplay/Table/TableHead";
export { default as TableBody } from "./material/dataDisplay/Table/TableBody";
export { default as TableFooter } from "./material/dataDisplay/Table/TableFooter";
export { default as TableRow } from "./material/dataDisplay/Table/TableRow";
export { default as TableCell } from "./material/dataDisplay/Table/TableCell";
export { default as VideoPlayer } from "./material/dataDisplay/videoPlayer/VideoPlayer";
export { default as Charts } from "./material/dataDisplay/charts/Charts";
export { default as RadialProgressBar } from "./material/dataDisplay/RadialProgressBar/RadialProgressBar";
//#endregion

//#region cbmis feedback
export * as Alert from "./material/feedback/Alert/Alert";
export * as Confirmation from "./material/feedback/Confirmation/Confirmation";
export { default as Note } from "./material/feedback/Note/Note";
//#endregion

//#region cbmisInput
export { default as Button } from "./material/inputs/Button/Button";
export { default as Checkbox } from "./material/inputs/CheckboxGroup/CheckboxGroup";
export { default as DatePicker } from "./material/inputs/DatePicker/DatePicker";
export { default as ErrorMessage } from "./material/inputs/ErrorMessage/ErrorMessage";
export { default as List } from "./material/inputs/List/List";
export { default as MultipleList } from "./material/inputs/MultipleList/MultipleList";
export { default as RadioButtons } from "./material/inputs/RadioButtons/RadioButtons";
export { default as Text } from "./material/inputs/Text/Text";
export { default as TextArea } from "./material/inputs/TextArea/TextArea";
export { default as Password } from "./material/inputs/Password/Password";
export { default as Number } from "./material/inputs/Number/Number";
export { default as Rating } from "./material/inputs/Rating/Rating";
export { default as RangeSlider } from "./material/inputs/RangeSlider/RangeSlider";
export { default as Switch } from "./material/inputs/Switch/Switch";
export { default as Color } from "./material/inputs/Color/Color";

//#endregion

//#region cbmis layout
export { default as Box } from "./material/layout/Box/Box";
export { default as Container } from "./material/layout/Container/Container";
export { default as Line } from "./material/layout/Line/Line";
export { default as Grid } from "./material/layout/Grid/Grid";

//cbmis navigation
export { default as BottomBar } from "./material/navigation/BottomBar/BottomBar";
export { default as Breadcrumbs } from "./material/navigation/Breadcrumbs/Breadcrumbs";
export { default as Drawer } from "./material/navigation/Drawer/Drawer";
export { default as Menu } from "./material/navigation/Menu/Menu";
export { default as MenuItem } from "./material/navigation/Menu/MenuItem";
export { default as Pagination } from "./material/navigation/Pagination/Pagination";
export { default as SpeedDial } from "./material/navigation/SpeedDial/SpeedDial";
export { default as SpeedDialAction } from "./material/navigation/SpeedDial/SpeedDialAction";
export { default as Stepper } from "./material/navigation/Stepper/Stepper";
export { default as Slideshow } from "./material/navigation/Slideshow/Slideshow";
export { default as Tabs } from "./material/navigation/Tabs/Tabs";
export { default as ParallaxScrolling } from "./material/navigation/ParallaxScrolling/ParallaxScrolling";
export { default as AppBar } from "./material/navigation/AppBar/AppBar";
export { default as GroupBar } from "./material/navigation/groupBar/GroupBar";
export { default as Nav } from "./material/navigation/nav/Nav";
export { default as BtnGoToTop } from "./material/navigation/btnGoToTop/BtnGoToTop";
//#endregion

//#region cbmis surfaces
export { default as Accordion } from "./material/surfaces/Accordion/Accordion";
export { default as AccordionTab } from "./material/surfaces/Accordion/AccordionTab";
export { default as AccordionSummary } from "./material/surfaces/Accordion/AccordionSummary";
export { default as AccordionDetails } from "./material/surfaces/Accordion/AccordionDetails";
export { default as Card } from "./material/surfaces/Card/Card";
export { default as CardFront } from "./material/surfaces/Card/CardFront";
export { default as CardBack } from "./material/surfaces/Card/CardBack";
export { default as CardImage } from "./material/surfaces/Card/CardImage";
export { default as CardDetails } from "./material/surfaces/Card/CardDetails";
export { default as Glass } from "./material/surfaces/Glass/Glass";
export { default as Loader } from "./material/surfaces/Loader/Loader";
export { default as Paper } from "./material/surfaces/Paper/Paper";
//#endregion

export { default as Icon } from "./icon/CbmisIcon";

