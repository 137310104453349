import React from 'react'
import { Grid, Typography } from '@cbmisorg/client-app'
import * as appColor from "../../../../../../appHelper/appColor"
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary'

const classes = {
  footer:{
    background:appColor?.App_Dark_Color,
    color:appColor?.App_Light_Color
  },
  companyName:{
    borderBottom:"2px solid #fff",
    paddingBottom:"5px"
  },
  copyright:{
    background:"#000",
    color:"#fff"
  }
}

function Footer({lang}) {
  const labels = dictionary?.components?.public?.header
  return (
    <React.Fragment>
    <Grid container my-0 py-0 sx={classes.footer} spacing="4">
      <Grid item xs="6" container alignSelf="flex-start">
        <Grid item xs="12">
          <Typography as="subtitle2" color="primary">{labels?.services?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.fullStackTraining?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.softwareDevelopment?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.projectManagement?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.cyberSecurity?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.artificialIntelligence?.[lang]}</Typography>
        </Grid>
      </Grid>
      <Grid item xs="6" container alignSelf="flex-start">
        <Grid item xs="12">
          <Typography as="subtitle2" color="primary">{labels?.joins?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.committee?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.partnership?.[lang]}</Typography>
        </Grid>
        <Grid item xs="12">
          <Typography as="caption">{labels?.investment?.[lang]}</Typography>
        </Grid>
      </Grid>
      </Grid>
      <Grid container justify="center" my-0 py-0 sx={classes.copyright}>
          <Grid item xs="12">
              <Typography as="caption">{labels?.copyRight?.[lang]}</Typography>
          </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Footer

