import { ClientTest,ClientFunction } from "@cbmisorg/client-app";
import * as appVariables from "./appVariables";

export const getNextStrURL = (appState, path = "") => {

 
};
export const logMessage = (strPageInfo, msg, title) => {
  ClientTest.logMessage(strPageInfo, title, msg, appVariables.App_IsInTestingMode);
};

export const {...clientFunction} = ClientFunction;
