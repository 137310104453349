import React, { useEffect, useReducer, useState } from 'react'
import * as ClientFunction from '../../system/cbmisFunction';

/**
 *
 * @param {any} appInitialState 
 * @param {React.Context<any>} AppContext 
 * @param {string} strAppLocalStorageName 
 * @param {boolean} blnIsEncrypt 
 * @param {string} strEncryptKey 
 * @param {boolean} blnIsTestingMode 
 * @returns {(props: any) => JSX.Element}
 */

function AppProvider(appInitialState, AppContext, strAppLocalStorageName = undefined, blnIsEncrypt = false, strEncryptKey = "secret", blnIsTestingMode = true) {
  const UPDATE_APP_STATE = "UPDATE_APP_STATE";
  function AppReducer(state, action) {
    switch (action?.type) {
      case UPDATE_APP_STATE:
        if (strAppLocalStorageName) {
          ClientFunction.setAppLocalStorage({ ...state, ...action.newAppState }, strAppLocalStorageName, blnIsEncrypt, strEncryptKey);
        }
        return { ...state, ...action.newAppState };

      default:
        if (strAppLocalStorageName) {
          ClientFunction.setAppLocalStorage({ ...state }, strAppLocalStorageName, blnIsEncrypt, strEncryptKey);
        }
        return { ...state };
    }
  }

  return function AppProviderImpl(props) {
    const [appState, appDispatch] = useReducer(AppReducer, appInitialState);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (strAppLocalStorageName) {
        try {
          if (localStorage.hasOwnProperty(strAppLocalStorageName) && localStorage.getItem(strAppLocalStorageName) !== null) {
            appDispatch({ type: UPDATE_APP_STATE, newAppState: ClientFunction.getAppLocalStorage(strAppLocalStorageName, blnIsEncrypt, strEncryptKey) });
            if (blnIsTestingMode) {
              console.log(`[${new Date().toLocaleTimeString("sv-SE")}] appState-localStorage updated ✅`);
            }
          }
          setIsLoading(false);
        } catch (error) {
          if (blnIsTestingMode) {
            console.log(`[${new Date().toLocaleTimeString("sv-SE")}] appState-localStorage can't update 💥`, error);
          }
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }, []);

    return (
      <AppContext.Provider
        value={{
          appState,
          appDispatch,
        }}
      >
        {isLoading ? null : props.children}
      </AppContext.Provider>
    );
  };
}

export default AppProvider