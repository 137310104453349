import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./iconsStyle.css";
import iconsAccess from "./iconsAccess.json";
import * as componentsFunction from "../helper/CbmisFunction";
import useCheckScreenSize from "../hook/useCheckScreenSize";
import { setSxClassName } from "../helper/convertJsCss";

const Icon = (props) => {
  const { icon, color, size, title, sx, hidden, disabled, dir, className, id, ...rest } = props;

  const screenWidth = useCheckScreenSize();
  const styleRef = useRef({ strSxClassName: "", strSXComponentID: componentsFunction.randomName("cbmis-icon") });
  const [isExistIcon, setIsExistIcon] = useState("");
  const getID = useRef(id || componentsFunction.randomName("icon"));

  const getRestProps = () => {
    const newRest = componentsFunction.filterPaddingAndMargin(rest);
    return { ...newRest };
  };

  useEffect(() => {}, [title, hidden, disabled, dir, className, id]);

  useLayoutEffect(() => {
    const isExist = Object.keys(iconsAccess).filter((item) => String(item).toLowerCase() === String(icon).toLowerCase());
    if (icon && isExist?.length > 0) {
      setIsExistIcon(isExist[0]);
    } else {
      setIsExistIcon("");
    }
  }, [icon]);

  useEffect(() => {
    if (isExistIcon && isExistIcon !== "") {
      let objStyle = {
        ...(color ? { color: `${componentsFunction.checkColorReturnValue(color)} !important` } : {}),
        ...(sx ? sx : {}),
      };

      setSxClassName(styleRef, styleRef.current.strSXComponentID, objStyle, "cbmis-icon");

      try {
        if(size){
          objStyle.width = `${size}`;
          objStyle.height = `${size}`;
          setSxClassName(styleRef, styleRef.current.strSXComponentID, objStyle, "cbmis-icon");
        }else if (styleRef.current?.strSxClassName && window?.getComputedStyle(document?.querySelector("." + styleRef.current?.strSxClassName))?.fontSize) {
          let IconsContainerFontSize = window.getComputedStyle(document.querySelector("." + styleRef.current?.strSxClassName)).fontSize;
          objStyle.width = `${IconsContainerFontSize}`;
          objStyle.height = `${IconsContainerFontSize}`;

          setSxClassName(styleRef, styleRef.current.strSXComponentID, objStyle, "cbmis-icon");
        }
      } catch {}
    }
  }, [sx, screenWidth, color, size, isExistIcon]);

  return (
    <React.Suspense fallback="">
      <div id={getID.current} className="cbmisIconMainContainer" key={`${icon}-${getID.current}`}>
        <div
          id={styleRef.current.strSXComponentID || id}
          className={`CbmisIconContainer ${props.onClick ? "cbmis-icon-hover" : ""} ${componentsFunction.CheckDirectionItem(dir)} ${componentsFunction.CheckDisabledItem(
            disabled
          )} ${componentsFunction.CheckHiddenItem(hidden)} ${componentsFunction.checkExistsMargin(rest)} ${componentsFunction.checkExistsPadding(rest)} ${
            className || ""
          }`}
          title={title || ""}
          {...getRestProps()}
        >
          {isExistIcon ? (
            <svg className={`cbmis-icon`} viewBox="0 0 24 24">
              {iconsAccess[isExistIcon]["circle"]
                ? iconsAccess[isExistIcon]["circle"].map((crl, index) => {
                    return <circle key={index} cx={crl["cx"]} cy={crl["cy"]} r={crl["r"]} />;
                  })
                : ""}
              {iconsAccess[isExistIcon]["path"]
                ? iconsAccess[isExistIcon]["path"].map((d, index) => {
                    return <path key={index} d={d} />;
                  })
                : ""}
              {iconsAccess[isExistIcon]["line"]
                ? iconsAccess[isExistIcon]["line"].map((line, index) => {
                    return (
                      <line
                        key={index}
                        x1={line["x1"]}
                        y1={line["y1"]}
                        x2={line["x2"]}
                        y2={line["y2"]}
                        strokeWidth={line["strokeWidth"] ? line["strokeWidth"] : ""}
                        strokeLinecap={line["strokeLinecap"] ? line["strokeLinecap"] : ""}
                        stroke="currentColor"
                      />
                    );
                  })
                : ""}
            </svg>
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Suspense>
  );
};

export default Icon;