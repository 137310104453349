import React from 'react'
import Home from './pages/home/Home'


function RouteLandingPage() {

  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  )
}

export default RouteLandingPage