
import React from 'react'
import {Grid,Typography, Box, Icon, Avatar, Container} from '@cbmisorg/client-app'
import { appStyle } from '../../../../../../appHelper/appStyle'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary'
import { App_Dark_Color, App_Primary_Color, App_Second_Color } from '../../../../../../appHelper/appColor'
const lstIcon = ["Settings","FactCheck","MobileFriendly","Language","Security"]

const classes={
    avatarIcon:{
        width:"50px !important",
        height:"50px !important",
        position:"absolute !important",
        top:"-50px !important"
    },
    container:{
        position:"relative",
        margin:"125px auto 50px",
        sm:{
            position:"relative",
            margin:"50px auto 50px",
        }
    },
    box:{
        borderColor:`${App_Primary_Color} !important`,
        minHeight:"100px"
    }
  }
function ProjectManagement({lang}) {
 const labels = dictionary?.components?.public?.landingPage?.home?.projectManagement
 

  return (
    <React.Fragment>
        <Container py-10  id="projectManagement">
           <Container>
            <Grid container sx={appStyle?.container} justify="center" py-5>
                <Grid item xs="12">
                    <Typography as="h4">{labels?.title?.[lang]}</Typography>
                </Grid>
            </Grid>
            {
                labels?.lstServices?.map((training,index)=>{
                    return(
                        <Container>
                            <Grid container sx={appStyle?.container} spacing={3} >
                                <Grid item xs="12" container spacing="2" alignSelf={'flex-start'}>
                                    <Grid item xs="12">
                                        <Typography as="h5" sx={appStyle?.title1} color={App_Second_Color}>{training?.name?.[lang]}</Typography>
                                    </Grid>
                                    <Grid item xs="12">
                                        <Typography as="body2" color={App_Second_Color}>{training?.describe?.[lang]}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs="12" container spacing="3">
                                    {
                                        training?.lst?.map((item,ind)=>{
                                            return(
                                                <Grid item xs="12" lg="4" container key={index} py-4>
                                                    <Box outline="2" sx={classes?.box}>
                                                        <Grid container justify="center">
                                                            <Grid item xs="12">
                                                                <Avatar mode="square" src={require("../../../../../../assets/images/avatarSquare.png")} color="transparent" p-0 sx={classes.avatarIcon}>
                                                                    <Icon icon={lstIcon?.[ind]} color={'primary'}/>
                                                                </Avatar>
                                                            </Grid>
                                                            <Grid item xs="12">
                                                                <Typography as="body2" color={App_Second_Color}>{item?.[lang]}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Container>
                    )
                })
            }
           </Container>
        </Container>
        
    </React.Fragment>
  )
}

export default ProjectManagement
