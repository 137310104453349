import { useContext } from "react";
import { SecureRoute, ClientFunction } from "@cbmisorg/client-app";
import appRouting from "./appRouting";
import { AppContext } from "../../configuration/contextapi/AppContext";
import * as appVariables from "../appVariables";


function GetUserRole() {
  const { appState } = useContext(AppContext);
  appState.clientInfo.strLanguage = appState.clientInfo.strDataLanguage;

  const getRole = () => {
    let appLocalStorage = ClientFunction.getAppLocalStorage(
      appVariables.App_LocalStorage_Name,
      appVariables.App_LocalStorage_Is_Encrypted,
      appVariables.App_LocalStorage_Encrypted_KEY
    );
    if (!appLocalStorage) {
      return appState?.userInfo?.bigUserRoleID;
    }

    return appLocalStorage?.userInfo?.bigUserRoleID;
  };

  return getRole();
}


export default SecureRoute.SecureRoute(appRouting, { userAuth: GetUserRole }, appRouting.Public_LandingPage.url, {
  strRoleType: "defaultRole",
  blnIsLocalStorageEncrypted: appVariables.App_LocalStorage_Is_Encrypted,
  strLocalStorageEncryptKey: appVariables.App_LocalStorage_Encrypted_KEY,
  blnIsTestingMode: appVariables.App_IsInTestingMode,
});