import { useEffect, useLayoutEffect, useState } from "react";

export default function useStickyHeader(offset = 0) {
    const [stick, setStick] = useState(false);

    const handleScroll = () => {
      if((window.scrollY > offset && stick !== true) || (window.scrollY <= offset && stick !== false)){
        setStick( window.scrollY > offset );
      }
    };

    useLayoutEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return(() => {
        window.removeEventListener('scroll', handleScroll);
      });
    },[stick]);

    return stick;
  }