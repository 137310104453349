import React, { useLayoutEffect } from "react";
import { AppBar, GroupBar, ClientDirection, Typography, Grid , Switch} from "@cbmisorg/client-app";
import useStickyHeader from "../../../../../../appHelper/appHook/useStickyHeader";
import { App_LocalStorage_Name } from "../../../../../../appHelper/appVariables";
import { clientFunction } from "../../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../../appHelper/appLanguage/dictionary";

const logoAr = require("../../../../../../assets/images/logoar.png");
const logoEn = require("../../../../../../assets/images/logoen.png");


function Header({ appState, appDispatch, navList, intRefreshCounter}) {
  const lang = appState?.clientInfo?.strLanguage;
  const blnIsScrolled = useStickyHeader(window?.screen?.height -60);

  const handelChangeLang = async (language) => {
    if (language !== lang) {
      appState.clientInfo.strLanguage = language;
      appState.clientInfo.strDataLanguage = language;
      appDispatch();
      ClientDirection.setDirectionByLanguage(language);
    }
  };

  const JXSButton = (
   <Grid container>
    <Grid item>
      <Typography as="body" color="light">{dictionary?.components?.public?.header?.language?.[lang]}</Typography>
    </Grid>
   <Grid item className={`${lang==="arb"?"langArb":"langEng"}`}>
    <Switch
      options={[
          {key:"eng",value:"En"},
          {key:"arb",value:"عربي"},
        ]}
        onChange={() => handelChangeLang(lang === "arb" ? "eng" : "arb")}
        defaultValue={lang==="eng"?{key:"eng",value:"En"}:{key:"arb",value:"عربي"}}
        mode="square1"
        icon="translate"
        showValue={true}
        p-1
        key={lang}
      />
    </Grid>
   </Grid>
  );

  useLayoutEffect(() => {
      const language = clientFunction?.getAppLocalStorage(App_LocalStorage_Name)?.clientInfo?.strLanguage
      ClientDirection.setDirectionByLanguage(language)
  }, [])

  return (
    <React.Fragment>
      <AppBar
        justify={"start"}
        className={!blnIsScrolled?"cbmis-app-bar-fixed":"cbmis-app-bar-Limiter"}
        sideBarTitle={JXSButton}
        sideBarClose={false}
        sideBarPosition={window.innerWidth <= "800" ?"top":lang==="arb"?"right":"left"}
        key={String(intRefreshCounter)}
        >

        <GroupBar sxMode={"navbar"} ssMode={"navbar"}>
          {blnIsScrolled ? <img src={lang==="arb"?logoAr:logoEn} alt="" height={"50px"} style={{ maxWidth: "98%" }} /> : ""}
        </GroupBar>
        <GroupBar sxMode="sidebar" ssMode="sidebar">
          {navList[0]}
        </GroupBar>

        <GroupBar sxMode="sidebar" ssMode="sidebar" sx={{borderBlockStart:"1px solid var(--cbmis-light)"}}>
          {navList[1]}
        </GroupBar>

      </AppBar>
    </React.Fragment>
  );
}

export default Header;
