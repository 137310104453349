import React, { forwardRef, useEffect, useRef, useState } from "react";
import "../../../style/main.css";
import "../inputs.css";
import "./List.css";
import * as componentsFunction from "../../../helper/CbmisFunction";
import { setSXStyle } from "../../../helper/convertJsCss";

import Menu from '../../navigation/Menu/Menu'
import MenuItem from '../../navigation/Menu/MenuItem'
import Text from "../Text/Text";
import Line from "../../layout/Line/Line";

const List = forwardRef((props, ref) => {
    const {id,label,placeholder,errorMessage,mode,
          color,icon,iconColor,adornment,endIcon,endAdornment,
          value,defaultValue,onChange,onFocus,options=[],optionStyle,autocomplete=true,
          sx,hidden,disabled,dir,className,readOnly,...rest} = props
    const nameID = useRef(id || componentsFunction.randomName("List"));

    const [initialState, setInitialState] = useState({
      selectedValue:"",
      selectedKey:"",
      openMenu:false,
      filterOption: options,
      autocompleteValue :""

    })

  //#region Effect
    useEffect(()=>{
      initialState.filterOption = options;
      if(value || defaultValue) {
          if(ref){
            ref.current.value = value?.value || defaultValue?.value || ""
            ref.current.key = value?.key || defaultValue?.key || ""
            ref.current.objValue={key:ref.current.key,value:ref.current.value}
          }

          initialState.selectedValue=value?.value || defaultValue?.value || ""
          initialState.selectedKey=value?.key || defaultValue?.key || ""
          setInitialState({...initialState})

      }
  },[options,value, defaultValue, ref])

    useEffect(()=>{
    },[id,label,placeholder,errorMessage,mode,
        color,icon,iconColor,adornment,endIcon,endAdornment,
        value,defaultValue,onChange,onFocus,options,optionStyle,autocomplete,
        sx,hidden,disabled,dir,className,rest,
        initialState])

    useEffect(() => {
      document.body.style.overflowY = "";
    }, []);


//#endregion

  //#region style

  //#region event
  const handleInputTextChange = (e) => {
    if (options && Array.isArray(options)) {
      const arrStartsWith = [];
      const arrIncludes = [];

      options.forEach((item,index) => {
        if (String(item.value).toLowerCase().startsWith(String(e.target.value).toLowerCase())) {
          arrStartsWith.push({
            ...item,
            props:{
              ...item.props,
              key:index + item.key
            }
          });
        } else if (String(item.value).toLowerCase().includes(String(e.target.value).toLowerCase())) {
          arrIncludes.push({
            ...item,
            props:{
              ...item.props,
              key:index + item.key
            }
          });
        }
      });

      const arrOption = [...arrStartsWith, ...arrIncludes];

      initialState.autocompleteValue = e.target.value
      initialState.filterOption = arrOption;
      setInitialState({ ...initialState });
    }
  };

  const handelChange = (event) => {
    const selectedValue = event.target.value;
    var selectedKey = event.target.value;
    if (event.target.id !== "") {
      selectedKey = event.target.id;
    }
    if(ref){
      ref.current.value = selectedValue
      ref.current.key = selectedKey
      ref.current.objValue = {key: selectedKey, value: selectedValue};
    }

    initialState.openMenu=false
    initialState.selectedValue=selectedValue
    initialState.selectedKey=selectedKey
    setInitialState({ ...initialState });

    if (onChange) {
      onChange(event);
    }
  };

    const handelFocus = (e) => {
      if(!readOnly){
        document.body.style.overflowY = "hidden";
        const left = e.target.getBoundingClientRect().left;
        const top = e.target.getBoundingClientRect().top;
        const width = e.target.offsetWidth;
        // const height = e.target.offsetHeight;
        const txlist = { top: `${top + 2}px !important`, left: `${left}px !important`, width: `${width}px !important` };
        setSXStyle(`cbmis-options-list-${nameID.current}`, txlist);
  
        initialState.openMenu=true
        setInitialState({ ...initialState });
      }
     
      if (onFocus) {
        onFocus(e);
      }
    };

    const handelMouseLeave = (e) => {
      if (initialState.openMenu) {
        initialState.openMenu = false;
        initialState.autocompleteValue = ""
        initialState.filterOption = options
        setInitialState({ ...initialState });
        document.body.style.overflowY = "";
      }
    };
  //#endregion

  //#region list
  const getOptionsList=()=>{
    const Options = []
    const SelectedOptions = []

      options.filter(option=>String(initialState.selectedKey) === String(option.key) && String(initialState.selectedValue) === String(option.value)).forEach(
        (option,index) => {
          if(option.key){
            Options.push(
              <React.Fragment key={option.key + index}>
                <MenuItem key={option.key} selected={String(initialState.selectedKey) === String(option.key) && String(initialState.selectedValue) === String(option.value)} sx={optionStyle || {}} p-1 onClick={handelMouseLeave}>
                      <input
                        type="radio"
                        className="cbmis-list-radio"
                        id={option.key}
                        key={option.key + index}
                        name={nameID.current}
                        defaultValue={option.value}
                        defaultChecked={String(initialState.selectedKey) === String(option.key) && String(initialState.selectedValue) === String(option.value)}
                        onChange={handelChange}
                      />
                      <span className={`cbmis-option-input-label cbmis-list-radio-label`}>{option.label || option.value}</span>
                </MenuItem>
              </React.Fragment>
            )
            }
        }
      )
      SelectedOptions.push(<Line />)

      if(initialState.selectedValue.length){
        Options.push(
          <dev className="cbmis-selected-options-list">
            {SelectedOptions}
          </dev>
        )
      }
      initialState.filterOption.filter(option=>String(initialState.selectedKey) !== String(option.key) && String(initialState.selectedValue) !== String(option.value)).forEach((option,index)=>{
          if(option.key){
              Options.push(
                <React.Fragment key={option.key+index}>
                  <MenuItem key={option.key} sx={optionStyle || {}} p-1 onClick={handelMouseLeave}>
                        <input
                          type="radio"
                          className="cbmis-list-radio"
                          id={option.key}
                          key={option.key+index}
                          name={nameID.current}
                          defaultValue={option.value}
                          checked={initialState?.selectedKey === option?.key && initialState?.selectedValue === option?.value}
                          onChange={handelChange}
                        />
                        <span className={`cbmis-option-input-label cbmis-list-radio-label`}>{option.label || option.value}</span>
                  </MenuItem>
                </React.Fragment>
              )
          }else{
              Options.push(
                <MenuItem  key={option.key+index} sx={optionStyle || {}} p-1 onClick={handelMouseLeave}>
                {option}
              </MenuItem>
              )
            }
      })

      return (
        <React.Fragment>
            <div className={autocomplete?"cbmis-options-list-with-autocomplete":"cbmis-options-list-without-autocomplete"}>{Options}</div>
            {
              autocomplete?(
                <React.Fragment>
                    <Line />
                    <dev className="autocompleteSearchInput">
                      <Text icon="search"
                            defaultValue={initialState.autocompleteValue}
                            onChange={handleInputTextChange}
                            color={color}
                            iconColor={color}
                            mode="classic"
                      />
                    </dev>
                </React.Fragment>
              ):""
            }
        </React.Fragment>
      )

    }

  //#endregion

  return (
      <React.Suspense fallback="">
      <div className={initialState.openMenu ? "lstContainerOpen" : "lstContainerClose"} onClick={handelMouseLeave} />
      <Text id={nameID.current} name={nameID.current}
                  ref={ref}
                  onFocus={handelFocus}
                  readOnly={true}
                  label={label}
                  placeholder={placeholder}
                  mode={mode}
                  color={color}
                  icon={icon}
                  iconColor={iconColor}
                  adornment={adornment}
                  sx={sx}
                  hidden={hidden}
                  disabled={disabled}
                  dir={dir}
                  className={className}
                  endIcon={endIcon || "arrowDropDown"}
                  errorMessage={errorMessage}
                  defaultValue={initialState.selectedValue}
            />
      <div className={componentsFunction.CheckDirectionItem(dir)}>
        {initialState.filterOption ? 
          <Menu  open={initialState.openMenu} className={`cbmis-options-list cbmis-options-list-${nameID.current} ${autocomplete?"cbmis-options-list-autocompleteSearch":""}`}>
                  {getOptionsList()}
          </Menu> 
        :""} 
      </div>
      </React.Suspense>
    )
});

export default List