import { appStyleConfig, updateAppConfig } from "..";
import { addClass, getSXUniqueClassName, replaceClass } from "./CbmisFunction";

//#region converter
export let objCssProperties = {
  //#region StandardLonghandProperties
  accentColor: "accent-color",
  alignContent: "align-content",
  alignItems: "align-items",
  alignSelf: "align-self",
  alignTracks: "align-tracks",
  animationComposition: "animation-composition",
  animationDelay: "animation-delay",
  animationDirection: "animation-direction",
  animationDuration: "animation-duration",
  animationFillMode: "animation-fill-mode",
  animationIterationCount: "animation-iteration-count",
  animationName: "animation-name",
  animationPlayState: "animation-play-state",
  animationTimeline: "animation-timeline",
  animationTimingFunction: "animation-timing-function",
  appearance: "appearance",
  aspectRatio: "aspect-ratio",
  backdropFilter: "backdrop-filter",
  backfaceVisibility: "backface-visibility",
  backgroundAttachment: "background-attachment",
  backgroundBlendMode: "background-blend-mode",
  backgroundClip: "background-clip",
  backgroundColor: "background",
  backgroundImage: "background-image",
  backgroundOrigin: "background-origin",
  backgroundPositionX: "background-position-x",
  backgroundPositionY: "background-position-y",
  backgroundRepeat: "background-repeat",
  backgroundSize: "background-size",
  blockOverflow: "block-overflow",
  blockSize: "block-size",
  borderBlockColor: "border-block-color",
  borderBlockEndColor: "border-block-end-color",
  borderBlockEndStyle: "border-block-end-style",
  borderBlockEndWidth: "border-block-end-width",
  borderBlockStartColor: "border-block-start-color",
  borderBlockStartStyle: "border-block-start-style",
  borderBlockStartWidth: "border-block-start-width",
  borderBlockStyle: "border-block-style",
  borderBlockWidth: "border-block-width",
  borderBottomColor: "border-bottom-color",
  borderBottomLeftRadius: "border-bottom-left-radius",
  borderBottomRightRadius: "border-bottom-right-radius",
  borderBottomStyle: "border-bottom-style",
  borderBottomWidth: "border-bottom-width",
  borderCollapse: "border-collapse",
  borderEndEndRadius: "border-end-end-radius",
  borderEndStartRadius: "border-end-start-radius",
  borderImageOutset: "border-image-outset",
  borderImageRepeat: "border-image-repeat",
  borderImageSlice: "border-image-slice",
  borderImageSource: "border-image-source",
  borderImageWidth: "border-image-width",
  borderInlineColor: "border-inline-color",
  borderInlineEndColor: "border-inline-end-color",
  borderInlineEndStyle: "border-inline-end-style",
  borderInlineEndWidth: "border-inline-end-width",
  borderInlineStartColor: "border-inline-start-color",
  borderInlineStartStyle: "border-inline-start-style",
  borderInlineStartWidth: "border-inline-start-width",
  borderInlineStyle: "border-inline-style",
  borderInlineWidth: "border-inline-width",
  borderLeftColor: "border-left-color",
  borderLeftStyle: "border-left-style",
  borderLeftWidth: "border-left-width",
  borderRightColor: "border-right-color",
  borderRightStyle: "border-right-style",
  borderRightWidth: "border-right-width",
  borderSpacing: "border-spacing",
  borderStartEndRadius: "border-start-end-radius",
  borderStartStartRadius: "border-start-start-radius",
  borderTopColor: "border-top-color",
  borderTopLeftRadius: "border-top-left-radius",
  borderTopRightRadius: "border-top-right-radius",
  borderTopStyle: "border-top-style",
  borderTopWidth: "border-top-width",
  bottom: "bottom",
  boxDecorationBreak: "box-decoration-break",
  boxShadow: "box-shadow",
  boxSizing: "box-sizing",
  breakAfter: "break-after",
  breakBefore: "break-before",
  breakInside: "break-inside",
  captionSide: "caption-side",
  caretColor: "caret-color",
  clear: "clear",
  clipPath: "clip-path",
  color: "color",
  colorAdjust: "color-adjust",
  colorScheme: "color-scheme",
  columnCount: "column-count",
  columnFill: "column-fill",
  columnGap: "column-gap",
  columnRuleColor: "column-rule-color",
  columnRuleStyle: "column-rule-style",
  columnRuleWidth: "column-rule-width",
  columnSpan: "column-span",
  columnWidth: "column-width",
  contain: "contain",
  content: "content",
  contentVisibility: "content-visibility",
  counterIncrement: "counter-increment",
  counterReset: "counter-reset",
  counterSet: "counter-set",
  cursor: "cursor",
  direction: "direction",
  display: "display",
  emptyCells: "empty-cells",
  filter: "filter",
  flexBasis: "flex-basis",
  flexDirection: "flex-direction",
  flexGrow: "flex-grow",
  flexShrink: "flex-shrink",
  flexWrap: "flex-wrap",
  float: "float",
  fontFamily: "font-family",
  fontFeatureSettings: "font-feature-settings",
  fontKerning: "font-kerning",
  fontLanguageOverride: "font-language-override",
  fontOpticalSizing: "font-optical-sizing",
  fontSize: "font-size",
  fontSizeAdjust: "font-size-adjust",
  fontSmooth: "font-smooth",
  fontStretch: "font-stretch",
  fontStyle: "font-style",
  fontSynthesis: "font-synthesis",
  fontVariant: "font-variant",
  fontVariantAlternates: "font-variant-alternates",
  fontVariantCaps: "font-variant-caps",
  fontVariantEastAsian: "font-variant-east-asian",
  fontVariantLigatures: "font-variant-ligatures",
  fontVariantNumeric: "font-variant-numeric",
  fontVariantPosition: "font-variant-position",
  fontVariationSettings: "font-variation-settings",
  fontWeight: "font-weight",
  forcedColorAdjust: "forced-color-adjust",
  gridAutoColumns: "grid-auto-columns",
  gridAutoFlow: "grid-auto-flow",
  gridAutoRows: "grid-auto-rows",
  gridColumnEnd: "grid-column-end",
  gridColumnStart: "grid-column-start",
  gridRowEnd: "grid-row-end",
  gridRowStart: "grid-row-start",
  gridTemplateAreas: "grid-template-areas",
  gridTemplateColumns: "grid-template-columns",
  gridTemplateRows: "grid-template-rows",
  hangingPunctuation: "hanging-punctuation",
  height: "height",
  hyphenateCharacter: "hyphenate-character",
  hyphens: "hyphens",
  imageOrientation: "image-orientation",
  imageRendering: "image-rendering",
  imageResolution: "image-resolution",
  initialLetter: "initial-letter",
  inlineSize: "inline-size",
  inputSecurity: "input-security",
  inset: "inset",
  insetBlock: "inset-block",
  insetBlockEnd: "inset-block-end",
  insetBlockStart: "inset-block-start",
  insetInline: "inset-inline",
  insetInlineEnd: "inset-inline-end",
  insetInlineStart: "inset-inline-start",
  isolation: "isolation",
  justifyContent: "justify-content",
  justifyItems: "justify-items",
  justifySelf: "justify-self",
  justifyTracks: "justify-tracks",
  left: "left",
  letterSpacing: "letter-spacing",
  lineBreak: "line-break",
  lineHeight: "line-height",
  lineHeightStep: "line-height-step",
  listStyleImage: "list-style-image",
  listStylePosition: "list-style-position",
  listStyleType: "list-style-type",
  marginBlock: "margin-block",
  marginBlockEnd: "margin-block-end",
  marginBlockStart: "margin-block-start",
  marginBottom: "margin-bottom",
  marginInline: "margin-inline",
  marginInlineEnd: "margin-inline-end",
  marginInlineStart: "margin-inline-start",
  marginLeft: "margin-left",
  marginRight: "margin-right",
  marginTop: "margin-top",
  maskBorderMode: "mask-border-mode",
  maskBorderOutset: "mask-border-outset",
  maskBorderRepeat: "mask-border-repeat",
  maskBorderSlice: "mask-border-slice",
  maskBorderSource: "mask-border-source",
  maskBorderWidth: "mask-border-width",
  maskClip: "mask-clip",
  maskComposite: "mask-composite",
  maskImage: "mask-image",
  maskMode: "mask-mode",
  maskOrigin: "mask-origin",
  maskPosition: "mask-position",
  maskRepeat: "mask-repeat",
  maskSize: "mask-size",
  maskType: "mask-type",
  mathDepth: "math-depth",
  mathShift: "math-shift",
  mathStyle: "math-style",
  maxBlockSize: "max-block-size",
  maxHeight: "max-height",
  maxInlineSize: "max-inline-size",
  maxLines: "max-lines",
  maxWidth: "max-width",
  minBlockSize: "min-block-size",
  minHeight: "min-height",
  minInlineSize: "min-inline-size",
  minWidth: "min-width",
  mixBlendMode: "mix-blend-mode",
  motionDistance: "motion-distance",
  motionPath: "motion-path",
  motionRotation: "motion-rotation",
  objectFit: "object-fit",
  objectPosition: "object-position",
  offsetAnchor: "offset-anchor",
  offsetDistance: "offset-distance",
  offsetPath: "offset-path",
  offsetRotate: "offset-rotate",
  offsetRotation: "offset-rotation",
  opacity: "opacity",
  order: "order",
  orphans: "orphans",
  outlineColor: "outline-color",
  outlineOffset: "outline-offset",
  outlineStyle: "outline-style",
  outlineWidth: "outline-width",
  overflowAnchor: "overflow-anchor",
  overflowBlock: "overflow-block",
  overflowClipBox: "overflow-clip-box",
  overflowClipMargin: "overflow-clip-margin",
  overflowInline: "overflow-inline",
  overflowWrap: "overflow-wrap",
  overflowX: "overflow-x",
  overflowY: "overflow-y",
  overscrollBehaviorBlock: "overscroll-behavior-block",
  overscrollBehaviorInline: "overscroll-behavior-inline",
  overscrollBehaviorX: "overscroll-behavior-x",
  overscrollBehaviorY: "overscroll-behavior-y",
  paddingBlock: "padding-block",
  paddingBlockEnd: "padding-block-end",
  paddingBlockStart: "padding-block-start",
  paddingBottom: "padding-bottom",
  paddingInline: "padding-inline",
  paddingInlineEnd: "padding-inline-end",
  paddingInlineStart: "padding-inline-start",
  paddingLeft: "padding-left",
  paddingRight: "padding-right",
  paddingTop: "padding-top",
  pageBreakAfter: "page-break-after",
  pageBreakBefore: "page-break-before",
  pageBreakInside: "page-break-inside",
  paintOrder: "paint-order",
  perspective: "perspective",
  perspectiveOrigin: "perspective-origin",
  placeContent: "place-content",
  pointerEvents: "pointer-events",
  position: "position",
  printColorAdjust: "print-color-adjust",
  quotes: "quotes",
  resize: "resize",
  right: "right",
  rotate: "rotate",
  rowGap: "row-gap",
  rubyAlign: "ruby-align",
  rubyMerge: "ruby-merge",
  rubyPosition: "ruby-position",
  scale: "scale",
  scrollBehavior: "scroll-behavior",
  scrollMargin: "scroll-margin",
  scrollMarginBlock: "scroll-margin-block",
  scrollMarginBlockEnd: "scroll-margin-block-end",
  scrollMarginBlockStart: "scroll-margin-block-start",
  scrollMarginBottom: "scroll-margin-bottom",
  scrollMarginInline: "scroll-margin-inline",
  scrollMarginInlineEnd: "scroll-margin-inline-end",
  scrollMarginInlineStart: "scroll-margin-inline-start",
  scrollMarginLeft: "scroll-margin-left",
  scrollMarginRight: "scroll-margin-right",
  scrollMarginTop: "scroll-margin-top",
  scrollPadding: "scroll-padding",
  scrollPaddingBlock: "scroll-padding-block",
  scrollPaddingBlockEnd: "scroll-padding-block-end",
  scrollPaddingBlockStart: "scroll-padding-block-start",
  scrollPaddingBottom: "scroll-padding-bottom",
  scrollPaddingInline: "scroll-padding-inline",
  scrollPaddingInlineEnd: "scroll-padding-inline-end",
  scrollPaddingInlineStart: "scroll-padding-inline-start",
  scrollPaddingLeft: "scroll-padding-left",
  scrollPaddingRight: "scroll-padding-right",
  scrollPaddingTop: "scroll-padding-top",
  scrollSnapAlign: "scroll-snap-align",
  scrollSnapMargin: "scroll-snap-margin",
  scrollSnapMarginBottom: "scroll-snap-margin-bottom",
  scrollSnapMarginLeft: "scroll-snap-margin-left",
  scrollSnapMarginRight: "scroll-snap-margin-right",
  scrollSnapMarginTop: "scroll-snap-margin-top",
  scrollSnapStop: "scroll-snap-stop",
  scrollSnapType: "scroll-snap-type",
  scrollbarColor: "scrollbar-color",
  scrollbarGutter: "scrollbar-gutter",
  scrollbarWidth: "scrollbar-width",
  shapeImageThreshold: "shape-image-threshold",
  shapeMargin: "shape-margin",
  shapeOutside: "shape-outside",
  tabSize: "tab-size",
  tableLayout: "table-layout",
  textAlign: "text-align",
  textAlignLast: "text-align-last",
  textCombineUpright: "text-combine-upright",
  textDecorationColor: "text-decoration-color",
  textDecorationLine: "text-decoration-line",
  textDecorationSkip: "text-decoration-skip",
  textDecorationSkipInk: "text-decoration-skip-ink",
  textDecorationStyle: "text-decoration-style",
  textDecorationThickness: "text-decoration-thickness",
  textEmphasisColor: "text-emphasis-color",
  textEmphasisPosition: "text-emphasis-position",
  textEmphasisStyle: "text-emphasis-style",
  textIndent: "text-indent",
  textJustify: "text-justify",
  textOrientation: "text-orientation",
  textOverflow: "text-overflow",
  textRendering: "text-rendering",
  textShadow: "text-shadow",
  textSizeAdjust: "text-size-adjust",
  textTransform: "text-transform",
  textUnderlineOffset: "text-underline-offset",
  textUnderlinePosition: "text-underline-position",
  top: "top",
  touchAction: "touch-action",
  transform: "transform",
  transformBox: "transform-box",
  transformOrigin: "transform-origin",
  transformStyle: "transform-style",
  transitionDelay: "transition-delay",
  transitionDuration: "transition-duration",
  transitionProperty: "transition-property",
  transitionTimingFunction: "transition-timing-function",
  translate: "translate",
  unicodeBidi: "unicode-bidi",
  userSelect: "user-select",
  verticalAlign: "vertical-align",
  visibility: "visibility",
  whiteSpace: "white-space",
  widows: "widows",
  width: "width",
  willChange: "will-change",
  wordBreak: "word-break",
  wordSpacing: "word-spacing",
  wordWrap: "word-wrap",
  writingMode: "writing-mode",
  zIndex: "z-index",
  zoom: "zoom",
  //#endregion
  ////////////////////////////////////////////////////////////////////////////////////////////
  //#region  StandardShorthandProperties
  all: "all",
  animation: "animation",
  background: "background",
  backgroundPosition: "background-position",
  border: "border",
  borderBlock: "border-block",
  borderBlockEnd: "border-block-end",
  borderBlockStart: "border-block-start",
  borderBottom: "border-bottom",
  borderColor: "border-color",
  borderImage: "border-image",
  borderInline: "border-inline",
  borderInlineEnd: "border-inline-end",
  borderInlineStart: "border-inline-start",
  borderLeft: "border-left",
  borderRadius: "border-radius",
  borderRight: "border-right",
  borderStyle: "border-style",
  borderTop: "border-top",
  borderWidth: "border-width",
  columnRule: "column-rule",
  columns: "columns",
  flex: "flex",
  flexFlow: "flex-flow",
  font: "font",
  gap: "gap",
  grid: "grid",
  gridArea: "grid-area",
  gridColumn: "grid-column",
  gridRow: "grid-row",
  gridTemplate: "grid-template",
  lineClamp: "line-clamp",
  listStyle: "list-style",
  margin: "margin",
  mask: "mask",
  maskBorder: "mask-border",
  motion: "motion",
  offset: "offset",
  outline: "outline",
  overflow: "overflow",
  overscrollBehavior: "overscroll-behavior",
  padding: "padding",
  placeItems: "place-items",
  placeSelf: "place-self",
  textDecoration: "text-decoration",
  textEmphasis: "text-emphasis",
  transition: "transition",
  //#endregion
  ////////////////////////////////////////////////////////////////////////////////////////////
  //#region VendorLonghandProperties
  MozAnimationDelay: "-moz-animation-delay",
  MozAnimationDirection: "-moz-animation-direction",
  MozAnimationDuration: "-moz-animation-duration",
  MozAnimationFillMode: "-moz-animation-fill-mode",
  MozAnimationIterationCount: "-moz-animation-iteration-count",
  MozAnimationName: "-moz-animation-name",
  MozAnimationPlayState: "-moz-animation-play-state",
  MozAnimationTimingFunction: "-moz-animation-timing-function",
  MozAppearance: "-moz-appearance",
  MozBackfaceVisibility: "-moz-backface-visibility",
  MozBorderBottomColors: "-moz-border-bottom-colors",
  MozBorderEndColor: "-moz-border-end-color",
  MozBorderEndStyle: "-moz-border-end-style",
  MozBorderEndWidth: "-moz-border-end-width",
  MozBorderLeftColors: "-moz-border-left-colors",
  MozBorderRightColors: "-moz-border-right-colors",
  MozBorderStartColor: "-moz-border-start-color",
  MozBorderStartStyle: "-moz-border-start-style",
  MozBorderTopColors: "-moz-border-top-colors",
  MozBoxSizing: "-moz-box-sizing",
  MozColumnCount: "-moz-column-count",
  MozColumnFill: "-moz-column-fill",
  MozColumnRuleColor: "-moz-column-rule-color",
  MozColumnRuleStyle: "-moz-column-rule-style",
  MozColumnRuleWidth: "-moz-column-rule-width",
  MozColumnWidth: "-moz-column-width",
  MozContextProperties: "-moz-context-properties",
  MozFontFeatureSettings: "-moz-font-feature-settings",
  MozFontLanguageOverride: "-moz-font-language-override",
  MozHyphens: "-moz-hyphens",
  MozImageRegion: "-moz-image-region",
  MozMarginEnd: "-moz-margin-end",
  MozMarginStart: "-moz-margin-start",
  MozOrient: "-moz-orient",
  MozOsxFontSmoothing: "-moz-osx-font-smoothing",
  MozPaddingEnd: "-moz-padding-end",
  MozPaddingStart: "-moz-padding-start",
  MozPerspective: "-moz-perspective",
  MozPerspectiveOrigin: "-moz-perspective-origin",
  MozStackSizing: "-moz-stack-sizing",
  MozTabSize: "-moz-tab-size",
  MozTextBlink: "-moz-text-blink",
  MozTextSizeAdjust: "-moz-text-size-adjust",
  MozTransformOrigin: "-moz-transform-origin",
  MozTransformStyle: "-moz-transform-style",
  MozTransitionDelay: "-moz-transition-delay",
  MozTransitionDuration: "-moz-transition-duration",
  MozTransitionProperty: "-moz-transition-property",
  MozTransitionTimingFunction: "-moz-transition-timing-function",
  MozUserFocus: "-moz-user-focus",
  MozUserModify: "-moz-user-modify",
  MozUserSelect: "-moz-user-select",
  MozWindowDragging: "-moz-window-dragging",
  MozWindowShadow: "-moz-window-shadow",
  msAccelerator: "-ms-accelerator",
  msBlockProgression: "-ms-block-progression",
  msContentZoomChaining: "-ms-content-zoom-chaining",
  msContentZoomLimitMax: "-ms-content-zoom-limit-max",
  msContentZoomLimitMin: "-ms-content-zoom-limit-min",
  msContentZoomSnapPoints: "-ms-content-zoom-snap-points",
  msContentZoomSnapType: "-ms-content-zoom-snap-type",
  msContentZooming: "-ms-content-zooming",
  msFilter: "-ms-filter",
  msFlexDirection: "-ms-flex-direction",
  msFlexPositive: "-ms-flex-positive",
  msFlowFrom: "-ms-flow-from",
  msFlowInto: "-ms-flow-into",
  msGridColumns: "-ms-grid-columns",
  msGridRows: "-ms-grid-rows",
  msHighContrastAdjust: "-ms-high-contrast-adjust",
  msHyphenateLimitChars: "-ms-hyphenate-limit-chars",
  msHyphenateLimitLines: "-ms-hyphenate-limit-lines",
  msHyphenateLimitZone: "-ms-hyphenate-limit-zone",
  msHyphens: "-ms-hyphens",
  msImeAlign: "-ms-ime-align",
  msLineBreak: "-ms-line-break",
  msOrder: "-ms-order",
  msOverflowStyle: "-ms-overflow-style",
  msOverflowX: "-ms-overflow-x",
  msOverflowY: "-ms-overflow-y",
  msScrollChaining: "-ms-scroll-chaining",
  msScrollLimitXMax: "-ms-scroll-limit-x-max",
  msScrollLimitXMin: "-ms-scroll-limit-x-min",
  msScrollLimitYMax: "-ms-scroll-limit-y-max",
  msScrollLimitYMin: "-ms-scroll-limit-y-min",
  msScrollRails: "-ms-scroll-rails",
  msScrollSnapPointsX: "-ms-scroll-snap-points-x",
  msScrollSnapPointsY: "-ms-scroll-snap-points-y",
  msScrollSnapType: "-ms-scroll-snap-type",
  msScrollTranslation: "-ms-scroll-translation",
  msScrollbar3dlightColor: "-ms-scrollbar-3dlight-color",
  msScrollbarArrowColor: "-ms-scrollbar-arrow-color",
  msScrollbarBaseColor: "-ms-scrollbar-base-color",
  msScrollbarDarkshadowColor: "-ms-scrollbar-darkshadow-color",
  msScrollbarFaceColor: "-ms-scrollbar-face-color",
  msScrollbarHighlightColor: "-ms-scrollbar-highlight-color",
  msScrollbarShadowColor: "-ms-scrollbar-shadow-color",
  msScrollbarTrackColor: "-ms-scrollbar-track-color",
  msTextAutospace: "-ms-text-autospace",
  msTextCombineHorizontal: "-ms-text-combine-horizontal",
  msTextOverflow: "-ms-text-overflow",
  msTouchAction: "-ms-touch-action",
  msTouchSelect: "-ms-touch-select",
  msTransform: "-ms-transform",
  msTransformOrigin: "-ms-transform-origin",
  msTransitionDelay: "-ms-transition-delay",
  msTransitionDuration: "-ms-transition-duration",
  msTransitionProperty: "-ms-transition-property",
  msTransitionTimingFunction: "-ms-transition-timing-function",
  msUserSelect: "-ms-user-select",
  msWordBreak: "-ms-word-break",
  msWrapFlow: "-ms-wrap-flow",
  msWrapMargin: "-ms-wrap-margin",
  msWrapThrough: "-ms-wrap-through",
  msWritingMode: "-ms-writing-mode",
  WebkitAlignContent: "-webkit-align-content",
  WebkitAlignItems: "-webkit-align-items",
  WebkitAlignSelf: "-webkit-align-self",
  WebkitAnimationDelay: "-webkit-animation-delay",
  WebkitAnimationDirection: "-webkit-animation-direction",
  WebkitAnimationDuration: "-webkit-animation-duration",
  WebkitAnimationFillMode: "-webkit-animation-fill-mode",
  WebkitAnimationIterationCount: "-webkit-animation-iteration-count",
  WebkitAnimationName: "-webkit-animation-name",
  WebkitAnimationPlayState: "-webkit-animation-play-state",
  WebkitAnimationTimingFunction: "-webkit-animation-timing-function",
  WebkitAppearance: "-webkit-appearance",
  WebkitBackdropFilter: "-webkit-backdrop-filter",
  WebkitBackfaceVisibility: "-webkit-backface-visibility",
  WebkitBackgroundClip: "-webkit-background-clip",
  WebkitBackgroundOrigin: "-webkit-background-origin",
  WebkitBackgroundSize: "-webkit-background-size",
  WebkitBorderBeforeColor: "-webkit-border-before-color",
  WebkitBorderBeforeStyle: "-webkit-border-before-style",
  WebkitBorderBeforeWidth: "-webkit-border-before-width",
  WebkitBorderBottomLeftRadius: "-webkit-border-bottom-left-radius",
  WebkitBorderBottomRightRadius: "-webkit-border-bottom-right-radius",
  WebkitBorderImageSlice: "-webkit-border-image-slice",
  WebkitBorderTopLeftRadius: "-webkit-border-top-left-radius",
  WebkitBorderTopRightRadius: "-webkit-border-top-right-radius",
  WebkitBoxDecorationBreak: "-webkit-box-decoration-break",
  WebkitBoxReflect: "-webkit-box-reflect",
  WebkitBoxShadow: "-webkit-box-shadow",
  WebkitBoxSizing: "-webkit-box-sizing",
  WebkitClipPath: "-webkit-clip-path",
  WebkitColumnCount: "-webkit-column-count",
  WebkitColumnFill: "-webkit-column-fill",
  WebkitColumnRuleColor: "-webkit-column-rule-color",
  WebkitColumnRuleStyle: "-webkit-column-rule-style",
  WebkitColumnRuleWidth: "-webkit-column-rule-width",
  WebkitColumnSpan: "-webkit-column-span",
  WebkitColumnWidth: "-webkit-column-width",
  WebkitFilter: "-webkit-filter",
  WebkitFlexBasis: "-webkit-flex-basis",
  WebkitFlexDirection: "-webkit-flex-direction",
  WebkitFlexGrow: "-webkit-flex-grow",
  WebkitFlexShrink: "-webkit-flex-shrink",
  WebkitFlexWrap: "-webkit-flex-wrap",
  WebkitFontFeatureSettings: "-webkit-font-feature-settings",
  WebkitFontKerning: "-webkit-font-kerning",
  WebkitFontSmoothing: "-webkit-font-smoothing",
  WebkitFontVariantLigatures: "-webkit-font-variant-ligatures",
  WebkitHyphenateCharacter: "-webkit-hyphenate-character",
  WebkitHyphens: "-webkit-hyphens",
  WebkitInitialLetter: "-webkit-initial-letter",
  WebkitJustifyContent: "-webkit-justify-content",
  WebkitLineBreak: "-webkit-line-break",
  WebkitLineClamp: "-webkit-line-clamp",
  WebkitMarginEnd: "-webkit-margin-end",
  WebkitMarginStart: "-webkit-margin-start",
  WebkitMaskAttachment: "-webkit-mask-attachment",
  WebkitMaskBoxImageOutset: "-webkit-mask-box-image-outset",
  WebkitMaskBoxImageRepeat: "-webkit-mask-box-image-repeat",
  WebkitMaskBoxImageSlice: "-webkit-mask-box-image-slice",
  WebkitMaskBoxImageSource: "-webkit-mask-box-image-source",
  WebkitMaskBoxImageWidth: "-webkit-mask-box-image-width",
  WebkitMaskClip: "-webkit-mask-clip",
  WebkitMaskComposite: "-webkit-mask-composite",
  WebkitMaskImage: "-webkit-mask-image",
  WebkitMaskOrigin: "-webkit-mask-origin",
  WebkitMaskPosition: "-webkit-mask-position",
  WebkitMaskPositionX: "-webkit-mask-position-x",
  WebkitMaskPositionY: "-webkit-mask-position-y",
  WebkitMaskRepeat: "-webkit-mask-repeat",
  WebkitMaskRepeatX: "-webkit-mask-repeat-x",
  WebkitMaskRepeatY: "-webkit-mask-repeat-y",
  WebkitMaskSize: "-webkit-mask-size",
  WebkitMaxInlineSize: "-webkit-max-inline-size",
  WebkitOrder: "-webkit-order",
  WebkitOverflowScrolling: "-webkit-overflow-scrolling",
  WebkitPaddingEnd: "-webkit-padding-end",
  WebkitPaddingStart: "-webkit-padding-start",
  WebkitPerspective: "-webkit-perspective",
  WebkitPerspectiveOrigin: "-webkit-perspective-origin",
  WebkitPrintColorAdjust: "-webkit-print-color-adjust",
  WebkitRubyPosition: "-webkit-ruby-position",
  WebkitScrollSnapType: "-webkit-scroll-snap-type",
  WebkitShapeMargin: "-webkit-shape-margin",
  WebkitTapHighlightColor: "-webkit-tap-highlight-color",
  WebkitTextCombine: "-webkit-text-combine",
  WebkitTextDecorationColor: "-webkit-text-decoration-color",
  WebkitTextDecorationLine: "-webkit-text-decoration-line",
  WebkitTextDecorationSkip: "-webkit-text-decoration-skip",
  WebkitTextDecorationStyle: "-webkit-text-decoration-style",
  WebkitTextEmphasisColor: "-webkit-text-emphasis-color",
  WebkitTextEmphasisPosition: "-webkit-text-emphasis-position",
  WebkitTextEmphasisStyle: "-webkit-text-emphasis-style",
  WebkitTextFillColor: "-webkit-text-fill-color",
  WebkitTextOrientation: "-webkit-text-orientation",
  WebkitTextSizeAdjust: "-webkit-text-size-adjust",
  WebkitTextStrokeColor: "-webkit-text-stroke-color",
  WebkitTextStrokeWidth: "-webkit-text-stroke-width",
  WebkitTextUnderlinePosition: "-webkit-text-underline-position",
  WebkitTouchCallout: "-webkit-touch-callout",
  WebkitTransform: "-webkit-transform",
  WebkitTransformOrigin: "-webkit-transform-origin",
  WebkitTransformStyle: "-webkit-transform-style",
  WebkitTransitionDelay: "-webkit-transition-delay",
  WebkitTransitionDuration: "-webkit-transition-duration",
  WebkitTransitionProperty: "-webkit-transition-property",
  WebkitTransitionTimingFunction: "-webkit-transition-timing-function",
  WebkitUserModify: "-webkit-user-modify",
  WebkitUserSelect: "-webkit-user-select",
  WebkitWritingMode: "-webkit-writing-mode",
  //#endregion
  ////////////////////////////////////////////////////////////////////////////////////////////
  //#region VendorShorthandProperties
  MozAnimation: "-moz-animation",
  MozBorderImage: "-moz-border-image",
  MozColumnRule: "-moz-column-rule",
  MozColumns: "-moz-columns",
  MozTransition: "-moz-transition",
  msContentZoomLimit: "-ms-content-zoom-limit",
  msContentZoomSnap: "-ms-content-zoom-snap",
  msFlex: "-ms-flex",
  msScrollLimit: "-ms-scroll-limit",
  msScrollSnapX: "-ms-scroll-snap-x",
  msScrollSnapY: "-ms-scroll-snap-y",
  msTransition: "-ms-transition",
  WebkitAnimation: "-webkit-animation",
  WebkitBorderBefore: "-webkit-border-before",
  WebkitBorderImage: "-webkit-border-image",
  WebkitBorderRadius: "-webkit-border-radius",
  WebkitColumnRule: "-webkit-column-rule",
  WebkitColumns: "-webkit-columns",
  WebkitFlex: "-webkit-flex",
  WebkitFlexFlow: "-webkit-flex-flow",
  WebkitMask: "-webkit-mask",
  WebkitMaskBoxImage: "-webkit-mask-box-image",
  WebkitTextEmphasis: "-webkit-text-emphasis",
  WebkitTextStroke: "-webkit-text-stroke",
  WebkitTransition: "-webkit-transition",
  //#endregion
  ////////////////////////////////////////////////////////////////////////////////////////////
  //#region ObsoleteProperties
  azimuth: "azimuth",
  boxAlign: "box-align",
  boxDirection: "box-direction",
  boxFlex: "box-flex",
  boxFlexGroup: "box-flex-group",
  boxLines: "box-lines",
  boxOrdinalGroup: "box-ordinal-group",
  boxOrient: "box-orient",
  boxPack: "box-pack",
  clip: "clip",
  gridColumnGap: "grid-column-gap",
  gridGap: "grid-gap",
  gridRowGap: "grid-row-gap",
  imeMode: "ime-mode",
  offsetBlock: "offset-block",
  offsetBlockEnd: "offset-block-end",
  offsetBlockStart: "offset-block-start",
  offsetInline: "offset-inline",
  offsetInlineEnd: "offset-inline-end",
  offsetInlineStart: "offset-inline-start",
  scrollSnapCoordinate: "scroll-snap-coordinate",
  scrollSnapDestination: "scroll-snap-destination",
  scrollSnapPointsX: "scroll-snap-points-x",
  scrollSnapPointsY: "scroll-snap-points-y",
  scrollSnapTypeX: "scroll-snap-type-x",
  scrollSnapTypeY: "scroll-snap-type-y",
  KhtmlBoxAlign: "-khtml-box-align",
  KhtmlBoxDirection: "-khtml-box-direction",
  KhtmlBoxFlex: "-khtml-box-flex",
  KhtmlBoxFlexGroup: "-khtml-box-flex-group",
  KhtmlBoxLines: "-khtml-box-lines",
  KhtmlBoxOrdinalGroup: "-khtml-box-ordinal-group",
  KhtmlBoxOrient: "-khtml-box-orient",
  KhtmlBoxPack: "-khtml-box-pack",
  KhtmlLineBreak: "-khtml-line-break",
  KhtmlOpacity: "-khtml-opacity",
  KhtmlUserSelect: "-khtml-user-select",
  MozBackgroundClip: "-moz-background-clip",
  MozBackgroundInlinePolicy: "-moz-background-inline-policy",
  MozBackgroundOrigin: "-moz-background-origin",
  MozBackgroundSize: "-moz-background-size",
  MozBinding: "-moz-binding",
  MozBorderRadius: "-moz-border-radius",
  MozBorderRadiusBottomleft: "-moz-border-radius-bottomleft",
  MozBorderRadiusBottomright: "-moz-border-radius-bottomright",
  MozBorderRadiusTopleft: "-moz-border-radius-topleft",
  MozBorderRadiusTopright: "-moz-border-radius-topright",
  MozBoxAlign: "-moz-box-align",
  MozBoxDirection: "-moz-box-direction",
  MozBoxFlex: "-moz-box-flex",
  MozBoxOrdinalGroup: "-moz-box-ordinal-group",
  MozBoxOrient: "-moz-box-orient",
  MozBoxPack: "-moz-box-pack",
  MozBoxShadow: "-moz-box-shadow",
  MozFloatEdge: "-moz-float-edge",
  MozForceBrokenImageIcon: "-moz-force-broken-image-icon",
  MozOpacity: "-moz-opacity",
  MozOutline: "-moz-outline",
  MozOutlineColor: "-moz-outline-color",
  MozOutlineRadius: "-moz-outline-radius",
  MozOutlineRadiusBottomleft: "-moz-outline-radius-bottomleft",
  MozOutlineRadiusBottomright: "-moz-outline-radius-bottomright",
  MozOutlineRadiusTopleft: "-moz-outline-radius-topleft",
  MozOutlineRadiusTopright: "-moz-outline-radius-topright",
  MozOutlineStyle: "-moz-outline-style",
  MozOutlineWidth: "-moz-outline-width",
  MozTextAlignLast: "-moz-text-align-last",
  MozTextDecorationColor: "-moz-text-decoration-color",
  MozTextDecorationLine: "-moz-text-decoration-line",
  MozTextDecorationStyle: "-moz-text-decoration-style",
  MozUserInput: "-moz-user-input",
  msImeMode: "-ms-ime-mode",
  OAnimation: "-o-animation",
  OAnimationDelay: "-o-animation-delay",
  OAnimationDirection: "-o-animation-direction",
  OAnimationDuration: "-o-animation-duration",
  OAnimationFillMode: "-o-animation-fill-mode",
  OAnimationIterationCount: "-o-animation-iteration-count",
  OAnimationName: "-o-animation-name",
  OAnimationPlayState: "-o-animation-play-state",
  OAnimationTimingFunction: "-o-animation-timing-function",
  OBackgroundSize: "-o-background-size",
  OBorderImage: "-o-border-image",
  OObjectFit: "-o-object-fit",
  OObjectPosition: "-o-object-position",
  OTabSize: "-o-tab-size",
  OTextOverflow: "-o-text-overflow",
  OTransform: "-o-transform",
  OTransformOrigin: "-o-transform-origin",
  OTransition: "-o-transition",
  OTransitionDelay: "-o-transition-delay",
  OTransitionDuration: "-o-transition-duration",
  OTransitionProperty: "-o-transition-property",
  OTransitionTimingFunction: "-o-transition-timing-function",
  WebkitBoxAlign: "-webkit-box-align",
  WebkitBoxDirection: "-webkit-box-direction",
  WebkitBoxFlex: "-webkit-box-flex",
  WebkitBoxFlexGroup: "-webkit-box-flex-group",
  WebkitBoxLines: "-webkit-box-lines",
  WebkitBoxOrdinalGroup: "-webkit-box-ordinal-group",
  WebkitBoxOrient: "-webkit-box-orient",
  WebkitBoxPack: "-webkit-box-pack",
  WebkitScrollSnapPointsX: "-webkit-scroll-snap-points-x",
  WebkitScrollSnapPointsY: "-webkit-scroll-snap-points-y",
  //#endregion
  ////////////////////////////////////////////////////////////////////////////////////////////
  //#region SvgProperties
  alignmentBaseline: "alignment-baseline",
  baselineShift: "baseline-shift",
  //clip: "clip",
  //clipPath: "clip-path",
  clipRule: "clip-rule",
  //color: "color",
  colorInterpolation: "color-interpolation",
  colorRendering: "color-rendering",
  //cursor: cursor,
  //direction: direction,
  //display: display,
  dominantBaseline: "dominant-baseline",
  fill: "fill",
  fillOpacity: "fill-opacity",
  fillRule: "fill-rule",
  //filter: "filter",
  floodColor: "flood-color",
  floodOpacity: "flood-opacity",
  //font: "font",
  //fontFamily: "font-family",
  //fontSize: "font-size",
  //fontSizeAdjust: "font-size-adjust",
  //fontStretch: "font-stretch",
  //fontStyle: "font-style",
  //fontVariant: "font-variant",
  //fontWeight: "font-weight",
  glyphOrientationVertical: "glyph-orientation-vertical",
  //imageRendering: "image-rendering",
  //letterSpacing: "letter-spacing",
  lightingColor: "lighting-color",
  //lineHeight: "line-height",
  marker: "marker",
  markerEnd: "marker-end",
  markerMid: "marker-mid",
  markerStart: "marker-start",
  //mask: mask,
  //opacity: opacity,
  //overflow: overflow,
  //paintOrder: "paint-order",
  //pointerEvents: "pointer-events",
  shapeRendering: "shape-rendering",
  stopColor: "stop-color",
  stopOpacity: "stop-opacity",
  stroke: "stroke",
  strokeDasharray: "stroke-dasharray",
  strokeDashoffset: "stroke-dashoffset",
  strokeLinecap: "stroke-linecap",
  strokeLinejoin: "stroke-linejoin",
  strokeMiterlimit: "stroke-miterlimit",
  strokeOpacity: "stroke-opacity",
  strokeWidth: "stroke-width",
  textAnchor: "text-anchor",
  //textDecoration: "text-decoration",
  //textRendering: "text-rendering",
  //unicodeBidi: "unicode-bidi",
  vectorEffect: "vector-effect",
  //visibility: visibility,
  //whiteSpace: "white-space",
  //wordSpacing: "word-spacing",
  //writingMode: "writing-mode",
  //#endregion
};

let objCssPseudo = {
  "&first-child": "first-child",
  "&last-child": "last-child",
  "&first-of-type": "first-of-type",
  "&last-of-type": "last-of-type",
  "&only-child": "only-child",
  "&hover": "hover",
  "&first-letter": "first-letter",
  "&first-line": "first-line",
  "&before": "before",
  "&after": "after",
  "&disabled": "disabled",
  "&active": "active",
  "&checked": "checked",
  "&empty": "empty",

  "&enabled": "enabled",
  "&focus": "focus",
  "&in-range": "in-range",
  "&invalid": "invalid",
  "&lang": "lang",
  "&link": "link",
};

let objCssHtmlElement = {
  label: "label",
};

const convert = ([key, value]) => {
  const objInputResult = { xs: {}, sm: {}, md: {}, lg: {}, xl: {} };
  convertPropsFromJsToCss(value, objInputResult, key);

  return objInputResult;
};

export function convertPropsFromJsToCss(_objInput, _objInputResult = { xs: {}, sm: {}, md: {}, lg: {}, xl: {} }, strPrevKey = null) {
  let objInput = _objInput;
  let objInputResult = _objInputResult;
  let objOp = { xs: {}, sm: {}, md: {}, lg: {}, xl: {} };
  let objCssProp = objCssProperties;
  let objNewCssPseudo = {};
  let objNewObjCssClasses = {};
  let objNewObjCssHTML = {};

  if (!objInput || typeof objInput !== "object" || Array.isArray(objInput)) {
    return objInputResult;
  }

  if (objInput && typeof objInput === "object" && !Array.isArray(objInput)) {
    let lstSource = Object.entries(objInput);
    lstSource.forEach(([key, value]) => {
      if (String(key).toLowerCase() === "content") {
        value = `~!${value}~!`;
      }
      if (objCssProp?.[key] && !objOp?.[strPrevKey] && typeof value !== "object") {
        objInputResult.xs[objCssProp[key]] = value;
        objInputResult.sm[objCssProp[key]] = value;
        objInputResult.md[objCssProp[key]] = value;
        objInputResult.lg[objCssProp[key]] = value;
        objInputResult.xl[objCssProp[key]] = value;

        convertPropsFromJsToCss(objInput[key], objInputResult, key);
      } else if (objOp?.[strPrevKey] && objCssProp?.[key] && typeof value !== "object") {
        // objInputResult[strPrevKey] = { ...objInputResult[strPrevKey] };
        // objInputResult[strPrevKey][objCssProp[key]] = value;

        switch (strPrevKey) {
          case "xs":
            if (!String(objInputResult.xs[objCssProp[key]]).toLowerCase().includes("!important") || String(value).toLowerCase().includes("!important")) {
              objInputResult.xs[objCssProp[key]] = value;
            }
            break;
          case "sm":
            if (!String(objInputResult.sm[objCssProp[key]]).toLowerCase().includes("!important") || String(value).toLowerCase().includes("!important")) {
              objInputResult.xs[objCssProp[key]] = value;
              objInputResult.sm[objCssProp[key]] = value;
            }
            break;
          case "md":
            if (!String(objInputResult.md[objCssProp[key]]).toLowerCase().includes("!important") || String(value).toLowerCase().includes("!important")) {
              objInputResult.xs[objCssProp[key]] = value;
              objInputResult.sm[objCssProp[key]] = value;
              objInputResult.md[objCssProp[key]] = value;
            }
            break;
          case "lg":
            if (!String(objInputResult.lg[objCssProp[key]]).toLowerCase().includes("!important") || String(value).toLowerCase().includes("!important")) {
              objInputResult.xs[objCssProp[key]] = value;
              objInputResult.sm[objCssProp[key]] = value;
              objInputResult.md[objCssProp[key]] = value;
              objInputResult.lg[objCssProp[key]] = value;
            }
            break;
          case "xl":
            if (!String(objInputResult.lg[objCssProp[key]]).toLowerCase().includes("!important") || String(value).toLowerCase().includes("!important")) {
              objInputResult.xs[objCssProp[key]] = value;
              objInputResult.sm[objCssProp[key]] = value;
              objInputResult.md[objCssProp[key]] = value;
              objInputResult.lg[objCssProp[key]] = value;
              objInputResult.xl[objCssProp[key]] = value;
            }
            break;

          default:
            break;
        }

        convertPropsFromJsToCss(objInput[key], objInputResult, key);
      } else if ((objOp?.[key] || objOp?.[strPrevKey] || objCssProp?.[key]) && !objCssPseudo?.[key]) {
        convertPropsFromJsToCss(objInput[key], objInputResult, key);
      } else if (objCssPseudo?.[key] && typeof value === "object") {
        const output = convert([key, value]);
        objNewCssPseudo[objCssPseudo?.[key]] = output;
      } else if (key[0] === "." && typeof value === "object") {
        const output = convert([key, value]);
        objNewObjCssClasses[key] = output;
      } else if (objCssHtmlElement?.[key] && typeof value === "object") {
        const output = convert([key, value]);
        objNewObjCssHTML[objCssHtmlElement?.[key]] = output;
      }
    });
  }
  return {
    objInputResult,
    objNewObjCssClasses,
    objNewCssPseudo,
    objNewObjCssHTML,
  };
}

export const setSXStyle = (className, objInput) => {
  if (className && objInput) {
    const { objInputResult, objNewObjCssClasses, objNewObjCssHTML, objNewCssPseudo } = convertPropsFromJsToCss(objInput);
    //const screenWidth = window.innerWidth;
    let xsScrSize = window.matchMedia("(max-width: 600px)");
    let smScrSize = window.matchMedia("screen and (min-width: 601px) and (max-width: 900px)");
    let mdScrSize = window.matchMedia("screen and (min-width: 901px) and (max-width: 1200px)");
    let lgScrSize = window.matchMedia("screen and (min-width: 1201px) and (max-width: 1536px)");
    let xlScrSize = window.matchMedia("(min-width: 1537px)");

    if (xsScrSize.matches) {
      convertObjToCSS(className, objInputResult?.xs);
      Object.entries(objNewObjCssHTML).forEach(([key, value]) => {
        convertObjToCSS(className, value.xs, key, null);
      });
      Object.entries(objNewObjCssClasses).forEach(([key, value]) => {
        convertObjToCSS(className, value.xs, key, null);
      });
      Object.entries(objNewCssPseudo).forEach(([key, value]) => {
        convertObjToCSS(className, value.xs, null, key);
      });
    } else if (smScrSize.matches) {
      convertObjToCSS(className, objInputResult?.sm);
      Object.entries(objNewObjCssHTML).forEach(([key, value]) => {
        convertObjToCSS(className, value.sm, key, null);
      });
      Object.entries(objNewObjCssClasses).forEach(([key, value]) => {
        convertObjToCSS(className, value.sm, key, null);
      });
      Object.entries(objNewCssPseudo).forEach(([key, value]) => {
        convertObjToCSS(className, value.sm, null, key);
      });
    } else if (mdScrSize.matches) {
      convertObjToCSS(className, objInputResult.md);
      Object.entries(objNewObjCssHTML).forEach(([key, value]) => {
        convertObjToCSS(className, value.md, key, null);
      });
      Object.entries(objNewObjCssClasses).forEach(([key, value]) => {
        convertObjToCSS(className, value.md, key, null);
      });
      Object.entries(objNewCssPseudo).forEach(([key, value]) => {
        convertObjToCSS(className, value.md, null, key);
      });
    } else if (lgScrSize.matches) {
      convertObjToCSS(className, objInputResult.lg);
      Object.entries(objNewObjCssHTML).forEach(([key, value]) => {
        convertObjToCSS(className, value.lg, key, null);
      });
      Object.entries(objNewObjCssClasses).forEach(([key, value]) => {
        convertObjToCSS(className, value.lg, key, null);
      });
      Object.entries(objNewCssPseudo).forEach(([key, value]) => {
        convertObjToCSS(className, value.lg, null, key);
      });
    } else if (xlScrSize.matches) {
      convertObjToCSS(className, objInputResult.xl);
      Object.entries(objNewObjCssHTML).forEach(([key, value]) => {
        convertObjToCSS(className, value.xl, key, null);
      });
      Object.entries(objNewObjCssClasses).forEach(([key, value]) => {
        convertObjToCSS(className, value.xl, key, null);
      });
      Object.entries(objNewCssPseudo).forEach(([key, value]) => {
        convertObjToCSS(className, value.xl, null, key);
      });
    }
  }
};

const convertObjToCSS = (className, objInput, childClass, pseudo) => {
  try {
    let headers = document.getElementsByTagName("head");

    if (
      !headers?.[0]?.firstChild ||
      String(headers?.[0]?.firstChild?.nodeName).toLowerCase() !== "style" ||
      typeof headers?.[0]?.firstChild?.getAttribute !== "function" ||
      String(headers?.[0]?.firstChild?.getAttribute("data-cbmis")) !== "css"
    ) {
      let style = document.createElement("style");
      style.setAttribute("data-cbmis", "css");
      style.setAttribute("title", "c12b56m0i1s");

      headers?.[0].insertBefore(style, headers?.[0].firstChild);

      let intOurIndexStyleSheet = 0;
      for (let i = 0; i < document.styleSheets.length; i++) {
        if (document.styleSheets[i].title === "c12b56m0i1s") {
          intOurIndexStyleSheet = i;
          break;
        }
      }

      updateAppConfig({ intStyleSheetSxIndex: intOurIndexStyleSheet });
    }

    const indexStyle = appStyleConfig.intStyleSheetSxIndex;

    let intNewRuleIndex = document.styleSheets[indexStyle].cssRules.length;
    if (appStyleConfig?.objCSSRuleSxIndex?.[className] !== null && appStyleConfig?.objCSSRuleSxIndex?.[className] !== undefined) {
      intNewRuleIndex = appStyleConfig?.objCSSRuleSxIndex?.[className];
      document.styleSheets[indexStyle].deleteRule(appStyleConfig?.objCSSRuleSxIndex?.[className]);
    }

    updateAppConfig({ objCSSRuleSxIndex: { ...appStyleConfig?.objCSSRuleSxIndex, [className]: intNewRuleIndex } });

    if (!childClass && !pseudo) {
      document.styleSheets[indexStyle].insertRule(`.${className}${String(String(JSON.stringify(objInput)).replaceAll('"', "")).replaceAll(",", ";")}`, intNewRuleIndex);
    }
    if (childClass) {
      document.styleSheets[indexStyle].insertRule(
        `.${className} ${childClass}${String(String(JSON.stringify(objInput)).replaceAll('"', "")).replaceAll(",", ";")}`,
        intNewRuleIndex
      );
    }
    if (pseudo) {
      document.styleSheets[indexStyle].insertRule(
        `.${className}:${pseudo}${String(String(String(JSON.stringify(objInput)).replaceAll('"', "")).replaceAll(",", ";")).replaceAll("~!", '"')}`,
        intNewRuleIndex
      );
    }
  } catch (error) {
    //console.log(error);
  }
};
//#endregion

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region set sx style

//#endregion

//#region set sx style
export function setSxClassName(styleRef, elementID, sx, className) {
  try {
    if (!styleRef.current.strSxClassName || styleRef.current.strSxClassName === "") {
      const gridElement = document.getElementById(elementID);
      styleRef.current.strSxClassName = String(getSXUniqueClassName(className));
      if (gridElement) {
        addClass(gridElement, styleRef.current.strSxClassName);
      }
    }
    setSXStyle(styleRef.current.strSxClassName, sx ? sx : {});
  } catch {}
}
//#endregion
