import React, { lazy, useContext,  useState } from 'react'
import { BtnGoToTop, Button, Nav, Typography, Loader, Grid, Line } from '@cbmisorg/client-app'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary'
import { AppContext } from '../../../../../configuration/contextapi/AppContext'

import Header from './sections/Header'
import Hero from './sections/Hero'
import ParallaxScrollingApp from '../../../../sharedUi/ParallaxScrollingApp'
import Footer from './sections/Footer'
import ProjectManagement from './sections/ProjectManagement'
import ConsultationServices from './sections/ConsultationServices'
import EducationalServices from './sections/EducationalServices'

const Training = lazy(() => import("./sections/Training"));
const CyberSecurity = lazy(() => import("./sections/CyberSecurity"));
const SoftwareDevelopment = lazy(() => import("./sections/SoftwareDevelopment"));
const ArtificialIntelligence = lazy(() => import("./sections/ArtificialIntelligence"));

const Committee = lazy(() => import("./sections/Committee"));
const Partnership = lazy(() => import("./sections/Partnership"));
const Investment = lazy(() => import("./sections/Investment"));
const ContactUs = lazy(() => import("./sections/ContactUs"));

function Home() {
  const {appState,appDispatch} = useContext(AppContext)
  const lang = appState?.clientInfo?.strLanguage
  const labels = dictionary?.components?.public?.header

  
  const [stateInfo, setStateInfo] = useState({intRefreshCounter: 0})

  const closeDrawer = () => {
    ++stateInfo.intRefreshCounter;
    setStateInfo({ ...stateInfo });
  };

  return (
    <React.Fragment>
      <Header
        appState={appState} appDispatch={appDispatch}
        navList={[
          <React.Fragment>
            <Nav label={<Typography color="light">{labels.home?.[lang]}</Typography>} path="#home" onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels?.fullStackTraining?.[lang]}</Typography>} path="#training"  onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels?.softwareDevelopment?.[lang]}</Typography>} path="#softwareDevelopment"  onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels?.projectManagement?.[lang]}</Typography>} path="#projectManagement"  onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels?.cyberSecurity?.[lang]}</Typography>} path="#cyberSecurity"  onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels?.artificialIntelligence?.[lang]}</Typography>} path="#artificialIntelligence"  onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels?.educationalServices?.[lang]}</Typography>} path="#educationalServices"  onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels?.consultationServices?.[lang]}</Typography>} path="#consultationServices"  onClick={closeDrawer}/>
            <Nav label={<Typography color="light">{labels.joins?.[lang]}</Typography>}
            menu={[
              <Nav label={<Typography color="light">{labels?.committee?.[lang]}</Typography>} path="#committee"  onClick={closeDrawer}/>,
              <Nav label={<Typography color="light">{labels?.partnership?.[lang]}</Typography>} path="#partnership"  onClick={closeDrawer}/>,
              <Nav label={<Typography color="light">{labels?.investment?.[lang]}</Typography>} path="#investment"  onClick={closeDrawer}/>,
            ]}
          />
            <Nav label={<Typography color="light">{labels?.contactUs?.[lang]}</Typography>} path="#contactus" onClick={closeDrawer}/>
          </React.Fragment>,
            <Nav onClick={()=>window.open("https://sheikhmohammedalsabah.com/","blank")}>
              <Typography as="caption" color="primary" sx={{fontWeight:"bold"}} m-auto py-2>
                {labels?.visitOffice?.[lang]}
              </Typography>
            </Nav>
          ]}
          intRefreshCounter={stateInfo?.intRefreshCounter}
        />
        <Hero lang={lang} />
        <ParallaxScrollingApp />
        <React.Suspense fallback={<Loader color="primary" backdrop={true}  sx={{position:"absolute !important", bottom:"10px !important"}}/>}>
        <Training lang={lang} />
        <ParallaxScrollingApp />
        <SoftwareDevelopment lang={lang} />
        <ParallaxScrollingApp />
        <ProjectManagement lang={lang} />
        <ParallaxScrollingApp />
        <CyberSecurity lang={lang} />
        <ParallaxScrollingApp />
        <ArtificialIntelligence lang={lang} />
        <ParallaxScrollingApp />
        <EducationalServices lang={lang} />
        <ParallaxScrollingApp />
        <ConsultationServices lang={lang} />
        <ParallaxScrollingApp />
        <Committee  lang={lang} />
        <Partnership lang={lang} />
        <Investment lang={lang} />

        <ContactUs lang={lang} />

        <Footer lang={lang} />
        <BtnGoToTop className={lang==="arb"?"arbLang":""}/>
      </React.Suspense>
    </React.Fragment>
  )
}

export default Home



// <Nav label={<Typography color="light">{labels?.employment?.[lang]}</Typography>} path="#employment"  onClick={closeDrawer}/>,
// 
