export const App_Name = "Group";
export const App_Version = "v.09.30.2023 9PM";
export const App_Setting_Mode = "localhost"; // localhost | testing
export const App_IsInTestingMode = true;

export const App_LocalStorage_Name = "appLocalStorageState";
export var App_LocalStorage_Is_Encrypted = false;
export var App_LocalStorage_Encrypted_KEY = "2023";


export var App_Server_Url = "";

export var App_Server_Url_GraphQL = App_Server_Url + "/graphql";
export var App_Server_Url_UploadFiles = App_Server_Url + "/upload/locally";
export var App_Server_Url_DownloadFiles = App_Server_Url + "/download/locally";
export var App_Server_Url_SendEmail = App_Server_Url + "/sendemail";

export const objUploadType = {
    cv : { fileType: "cv", id: "govID", sizeMb: 20, accept: "FILE" },
  };

  export const objMailType = {
    Contact_US: 5610433647,
    Enrollment: 6609632190,
  };

  export const objMailPurpose = {
    Contact_US: {
      Complaint: 2617277961,
      Suggestion: 1356313836,
      Inquiry: 2723785096,
    },
    Enrollment: {
      Enrollment_Request: 1641651315,
      Partnership_Request: 3329688095,
      Investment_Request: 3706261087,
    },
  };
  