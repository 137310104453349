import React from 'react'
import { Grid, Typography, Paper} from "@cbmisorg/client-app"
import * as appColor from '../../../../../../appHelper/appColor';
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';

import logoAr from '../../../../../../assets/images/logoar.png';
import logoEn from '../../../../../../assets/images/logoen.png';

const classes = {
    background: {
        width:"100vw !important",
        minHeight:"100vh !important",
        background:`url(${require("../../../../../../assets/images/hero.jpg")}) !important`,
        backgroundSize: "cover !important",
        backgroundPosition: "50% 50% !important",
        backgroundRepeat: "no-repeat !important",
        objectFit: "cover !important",
        verticalAlign: "middle !important",
        justifyContent:"center !important"
        
    },
    paper:{
        backgroundColor:`${appColor.App_Dark_Color}cc !important`,
        borderRadius: "1rem !important",
        // margin:"auto !important",
    },
    title:{
      fontFamily: "fangsong !important",
      textAlign:"center !important",
      fontWeight:"600 !important",
    },
};

function Hero({lang}) {
  const labels = dictionary?.components?.public?.landingPage?.home?.hero

    return (
        <React.Fragment>
                <Grid container id="hero" sx={classes.background} py-0 justify={'center'}>
                    <Grid item xs="0" md="6" lg="5" alignSelf={"flex-start"} pt-10>
                      <Paper sx={classes.paper}>
                          <Grid container spacing="0" justify={"center"}>
                              <Grid item xs="12">
                                <img src={lang === "arb"?logoAr:logoEn} alt="" width="90%" />
                              </Grid>
                              <Grid item xs="10">
                                <Typography as="body1" color={'primary'} sx={classes?.title}>{labels?.p1?.[lang]}</Typography>
                              </Grid>
                          </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs="12" md="0" lg="0" alignSelf={"flex-start"} pt-10>
                          <Grid container spacing="0" justify={"center"}>
                              <Grid item xs="12">
                                <img src={lang === "arb"?logoAr:logoEn} alt="" width="90%" />
                              </Grid>
                              <Grid item xs="10">
                                <Typography as="body1" color={'primary'} sx={classes?.title}>{labels?.p1?.[lang]}</Typography>
                              </Grid>
                          </Grid>
                    </Grid>
                    <Grid item xs="12" pt-xl-10 mt-xl-10 pt-lg-10 mt-lg-10 pt-md-10 mt-md-10>
                      <Typography as="h5" color={"primary"} sx={classes?.title} pt-xl-10 pt-lg-10 pt-md-10>{labels?.subtitle?.[lang]}</Typography>  
                    </Grid>
                </Grid>
                <Grid container>
                  
                </Grid>
        </React.Fragment>
    )
}

export default Hero

