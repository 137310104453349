export const Public_LandingPage = "/";
export const Public_Committee = "/committee";
export const Public_Partnership  = "/partnership";
export const Public_Investment = "/investment";

export const Contact_Us = "/contactus";
export const FAQ = "/faq";


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


